
import { createRouter, createWebHistory } from 'vue-router';
import { ElMessage } from 'element-plus'
import { checkIfLogin } from '@/func/func';
const router = createRouter({
    history: createWebHistory(),
    routes: [

        {
            path: '/',
            name: '', 
            component: () => import('../views/HomeView.vue'),  // 直接将 storeView 作为主页组件
        },           
        {
            path: '/home',
            name: 'home', 
            component: () => import('../views/HomeView.vue'),  
        },        
        {
            path: '/login',
            name: 'login', 
            component: () => import('../views/LoginView.vue'),  
        },
        {
            path: '/market',
            name: 'market', 

            component: () => import('../views/MarketView.vue'),  //
        },        
        {
            path: '/collection',
            name: 'collection', 
            component: () => import('../views/CollectionView.vue'),  // 
        },       
         {
            path: '/inventory',
            name: 'inventory', 
            meta: { requiresAuth: true },
            component: () => import('../views/user/InventoryView.vue'),  // 
        },         
        {
            path: '/store',
            name: 'store', 
            meta: { requiresAuth: true },
            component: () => import('../views/user/StoreView.vue'),  // 
        },       
         {
            path: '/user',
            name: 'user', 
            meta: { requiresAuth: true },
            component: () => import('../views/user/UserCenterView.vue'),  // 
        },
    ]
}
)




  router.beforeEach((to, from, next) => {
    const jwtToken = localStorage.getItem('jwtToken');
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    if (requiresAuth && !jwtToken) {
        // 如果需要认证但没有 JWT 令牌，重定向到登录页面
        ElMessage({
            message: '请先进行登录!',
            type: 'error',
        })
        next({ name: 'login' });
    } else {

        if(checkIfLogin()&&to.name === 'login'){
            next({ name: 'home' }); // 重定向到 Home 页
        }
        
        next();
    }
});
 
export default router

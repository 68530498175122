import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'


createApp(App).mount('#app')


const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }

  

app.use(ElementPlus)
app.config.globalProperties.$axios = axios
app.use(router)
app.mount('#app')

// window._paq.push(['trackPageView']); 

// axios.defaults.baseURL = "http://localhost:8099"
axios.defaults.baseURL = "https://csgo520.com"

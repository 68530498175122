


function getExteriorName(id) {
    switch (id) {
        case 1:
            return "崭新出厂";
        case 2:
            return "略有磨损";
        case 3:
            return "久经沙场";
        case 4:
            return "破损不堪";
        case 5:
            return "战痕累累";
    }
}

function checkIfLogin() {
    const token = localStorage.getItem('jwtToken'); // 获取本地存储的JWT
    if (!token) {
      return false; // 没有令牌，未登录
    }
  
    try {
      // 拆分JWT（格式：header.payload.signature）
      const parts = token.split('.');

      if (parts.length !== 3) {

        return false; // 格式不正确
      }
  
      // 解码 payload（第二部分）
      const payload = JSON.parse(atob(parts[1]));
  
      // 检查是否过期
      const currentTime = Math.floor(Date.now() / 1000); // 当前时间（秒）
      if (payload.exp && currentTime > payload.exp) {
        return false;
      }
  
      return true; // 令牌有效
    } catch (error) {
      return false; // 解析失败，可能是无效令牌
    }
  }
  

export { getExteriorName } ;
export { checkIfLogin } ;

